<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="trades !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0">

        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="market"
                    class="d-inline-block mr-1"
                    placeholder="Search markets..."
                />
              </div>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>State : </label>
              <v-select
                  v-model="state"
                  :options="perState"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector w-75 mx-50"
              />
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Count : </label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector w-75 mx-50"
              />
            </b-col>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="trades"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found !"
        >
          <!-- Column: delete -->
          <template #cell(userInfo)="data">
            <p>{{data.item.userInfo.userName}}</p>
          </template>
          <template #cell(createDate)="data">
            {{data.item.createDate.slice(0,10)}}
            -
            {{data.item.createDate.slice(11,19)}}
          </template>
          <template #cell(amount)="data">
            <p>{{data.item.amount}} $</p>
          </template>
          <template #cell(state)="data">
            <b-badge v-if="data.item.state === 1" variant="secondary">
              Pending
            </b-badge>
            <b-badge v-if="data.item.state === 2" variant="success">
              Win
            </b-badge>
            <b-badge v-else-if="data.item.state === 3" variant="danger">
              Lose
            </b-badge>
          </template>
          <template #cell(profit)="data">
            <span v-if="data.item.profit !== null" class="text-success"> {{ data.item.profit }} $</span>
            <span v-else-if="data.item.loss !== null" class="text-danger"> {{ data.item.loss }} $</span>
          </template>
          <template #cell(type)="data">
            <span>
              <feather-icon v-if="data.item.type === 1" icon="ArrowUpIcon" size="20" class="text-success" />
              <feather-icon v-if="data.item.type === 2" icon="ArrowDownIcon" size="20" class="text-danger" />
            </span>
          </template>
          <template #cell(Detail)="data">
            <b-link class="cursor-pointer" :to="`/apps/trades/${data.item.tradeId}`">
              <feather-icon icon="EyeIcon" size="20" class="text-primary" />
            </b-link>
          </template>
        </b-table>
        <div class="mx-2 mb-2 ">
          <b-row>
            <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup,BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {CandleGetAllRequest} from "@/libs/Api/Candle";
import {TradeGetAllRequest} from "@/libs/Api/Trade";

export default {
  title: 'Trades List',
  name: 'TradesList',
  data() {
    return {
      trades: null,
      totalCount: null,
      showOverlay:false,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      CategoryName:'',
      TicketType:0,
      state:'',
      perPageOptions: [10, 20, 30, 40, 50],
      perState:[
        {label:'All',value:''},
        {label:'Pending',value:1},
        {label:'Win',value:2},
        {label:'Lose',value:3}
      ],
      myTableColumns: [
        {
          key: 'tradeId',
          label: 'Trade id'
        },
        {
          key: 'market',
          label: 'Currency'
        },
        {
          key: 'userInfo',
          label: 'User'
        },
        {
          key: 'type',
          label: 'Up / Down'
        },
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'state',
          label: 'Result'
        },
        {
          key: 'profit',
          label: 'Profit / Loss'
        },
        {
          key: 'createDate',
          label: 'Create Date'
        },
        // {
        //   key: 'Detail',
        //   label: 'show'
        // },
      ],
      market:'',
      pageNumber: 1,
      SelectedTicket: null,
    }
  },
  async created() {
    await this.GetAllTrades()
  },
  watch: {
    searchCommand: function () {
      this.GetAllTrades()
    },
    currentPage: function () {
      this.GetAllTrades()
    },
    state: function () {
      this.GetAllTrades()
    },
    market: function () {
      this.GetAllTrades()
    },
    perPage: function () {
      this.GetAllTrades()
    },
  },
  methods:{
    async GetAllTrades(){
      let _this = this;
      _this.showOverlay = true;
      let tradeGetAllRequest = new TradeGetAllRequest(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        market:_this.market,
        state:_this.state
      }
      tradeGetAllRequest.setParams(data);
      await tradeGetAllRequest.fetch(function (content) {
        _this.trades = content.trades;
        _this.totalCount = content.tradesCount;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
}
</script>

<style scoped>

</style>
